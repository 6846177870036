import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import "./style.scss";
import LinkedinCard from "../../components/card/linkedinCard/linkedinCard";

const MerciPourVotreDemande = ({location}) => {
  let about;
  switch(location?.state?.about) {
      case "candidature":
          about = "candidature";
          break;
      case "reclamation":
          about = "reclamation";
          break;
      default:
          about = "demande";
          break;
  }

  return (
    <>
      <Helmet>
        <body className="merci" />
      </Helmet>
      <Layout>
        <SEO title={about === 'reclamation' ? 'Réclamation réceptionnée' : `Merci pour votre ${about}`} />
        <h1>
          {about === 'reclamation' ? (
            <>
              <strong>Nous avons bien reçu votre réclamation.</strong> <br /> Nos services
              vous répondront dans les plus brefs délais.
            </>
          ) : (
            <>
              <strong>Merci pour votre {about}&nbsp;!</strong> <br /> Nous vous
              recontacterons dès que possible.
            </>
          )}
        </h1>

        <section className="merci__section">
          <LinkedinCard />
        </section>
      </Layout>
    </>
  );
};

export default MerciPourVotreDemande;
